<template>
    <div class="device_select">
        <van-button type="primary" @click="getDeviceInfo" style="border-radius: 0.3rem;">{{btnText === 0?'选择设备':'重新选择'}}</van-button>
        <!-- 搜索表单弹框 -->
        <van-dialog v-model="deviceSelectShow" title=" " show-cancel-button @confirm="onConfirm" @cancel="onClose">
            <el-form ref="form" :model="form" label-width="60px">
                <el-form-item label="车间" required>
                    <el-select v-model="form.workshop" placeholder="请选择车间">
                        <el-option label="8车间A区" value="8车间A区"></el-option>
                        <el-option label="8车间B区" value="8车间B区"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="产线" required>
                    <el-select v-model="form.line" placeholder="请选择产线">
                        <el-option label="1线" value="1线"></el-option>
                        <el-option label="2线" value="2线"></el-option>
                        <el-option label="3线" value="3线"></el-option>
                        <el-option label="4线" value="4线"></el-option>
                        <el-option label="5线" value="5线"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="机台" required>
                    <el-select v-model="form.device" placeholder="请选择设备">
                        <el-option label="组框机1" value="组框机1"></el-option>
                        <el-option label="组框机2" value="组框机2"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </van-dialog>
    </div>
</template>

<script>
import Vue from 'vue';
import { Button, Dialog, Form, Field, DropdownMenu, DropdownItem, Popup, Picker } from 'vant';

Vue.use(Button);
Vue.use(Dialog);
Vue.use(Form);
Vue.use(Field);
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Popup)
Vue.use(Picker)
export default {
    data() {
        return {
            deviceSelectShow: false,
            username: '',
            password: '',
            value: '',
            columns: ['杭州'],
            showPicker: false,
            form: {
                workshop: '',
                line: '',
                device: '',
            }
        }
    },
    props: ["btnText"],
    methods: {
        onSubmit(values) {
            console.log('submit', values);
        },
        // 选择设备信息
        getDeviceInfo() {
            this.deviceSelectShow = true
        },
        onConfirm() {
            this.$emit('getDevice', this.form)
        },
        onClose() {
            this.form.workshop = ''
            this.form.line = ''
            this.form.device = ''
        }
    },
    watch: {
        btnText: {
            handler(val) {
                console.log(val);
            },
            immediate: true
        }
    }
}
</script>

<style lang="scss" scoped>
.device_select {
    width: 100%;

    .van-button {
        width: 100%;
    }
}
</style>