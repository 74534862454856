<template>
    <div class="enter_fault">
        <div>录入设备故障单</div>
        <!-- 设备选择 -->
        <van-grid :column-num="1" :gutter="10" class="device_info">
            <van-grid-item>
                <span style="font-size: 12px;">{{ deviceInfo.length === 0 ? '未选择' : deviceInfo }}</span>
            </van-grid-item>
        </van-grid>
        <div class="select_button">
            <device-select @getDevice="getDevice" :btnText="deviceInfo.length"></device-select>
        </div>
        <!-- 故障选择 -->
        <div class="form_label">故障现象</div>
        <el-select v-model="faultType" placeholder="请选择故障类型" filterable allow-create>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.label">
            </el-option>
        </el-select>
        <!-- 时间 -->
        <div class="form_label">发生时间</div>
        <el-date-picker v-model="occurrenceTime" value-format="yyyy/MM/dd HH:mm" type="datetime" placeholder="选择开始时间">
        </el-date-picker>
        <!-- 持续时间 -->
        <div class="form_label">已持续时间(分钟)</div>
        <el-input v-model="duration" placeholder="请输入内容"></el-input>
        <!-- 操作按钮 -->
        <div class="opreation_button">
            <van-button type="primary" @click="onConfirm">保存</van-button>
            <van-button type="default" @click="onCancel">取消</van-button>
        </div>
        <van-dialog v-model="cancelDialog" title=" " show-cancel-button @confirm="cancelConfirm" @cancel="cancelClose">
            <div>确认要取消填写么</div>
        </van-dialog>
    </div>
</template>

<script>
import Vue from 'vue'

import { Grid, GridItem, Button, Dialog, Notify } from 'vant';

Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Button);
Vue.use(Dialog);

import DeviceSelect from '../components/enter/DeviceSelect';
export default {
    data() {
        return {
            deviceInfo: '',
            btnText: '选择设备',
            occurrenceTime: '',
            options: [{
                value: '选项1',
                label: '线体气缸上位异常'
            }, {
                value: '选项2',
                label: '长边1边框归正紧位异常'
            }, {
                value: '选项3',
                label: '短边1夹抓松位异常'
            }],
            duration: '',
            faultType: '',
            cancelDialog: false
        }
    },
    components: {
        DeviceSelect
    },
    methods: {
        // 点击确认
        onConfirm() {
            if (this.deviceInfo.length !== 0 && this.faultType != '' && this.occurrenceTime != '' && this.duration != '') {
                let data = JSON.parse(sessionStorage?.getItem('faultData'))?.length ? JSON.parse(sessionStorage.getItem('faultData')) : []
                console.log(data);
                let item = this.deviceInfo + ' ' + this.occurrenceTime + '已持续' + this.duration + '分钟' + this.faultType
                let iid = data.length + 1
                data.push({ id: iid, info: item, isConfirm: false })
                sessionStorage.setItem('faultData', JSON.stringify(data))
                this.$router.push({
                    path: '/success',
                    query: { id: iid }
                })
            } else {
                Notify('请填写完整故障信息')
            }

        },
        // 回显选中的设备
        getDevice(form) {
            this.deviceInfo = form.workshop + '-' + form.line + '-' + form.device
        },
        // 点击取消
        onCancel() {
            this.cancelDialog = true
        },
        // 取消操作弹窗确认
        cancelConfirm() {
            this.deviceInfo = ''
            this.faultType = ''
            this.occurrenceTime = ''
            this.duration = ''
        },
        // 取消操作弹窗取消
        cancelClose() { },
    }
}
</script>

<style lang="scss" scoped>
.enter_fault {
    background-color: #f7f8fa;
    height: 100vh;
    box-sizing: border-box;
    padding-top: 1.25rem;

    .device_info {
        margin: 0 .625rem;
        height: 8.75rem;
        margin-top: .625rem;
    }

    .select_button {
        margin: 1.25rem 1.25rem 0;
    }

    .form_label {
        text-align: left;
        padding-left: 1.25rem;
        padding-bottom: 0.625rem;
        padding-top: 0.625rem;
    }

    .el-select {
        width: calc(100% - 2.5rem);
    }

    .el-input {
        width: calc(100% - 2.5rem);
    }

    .opreation_button {
        position: fixed;
        bottom: 0;
        width: 100%;

        .van-button {
            border-radius: 0;
            width: 50%;
        }
    }
}
</style>